import { createContext, useContext, useState, useEffect } from 'react';

const GeolocationContext = createContext();

export const GeolocationProvider = ({ children }) => {
    const [coordinates, setCoordinates] = useState(null);

    const fetchGeolocation = async () => {
        try {
            const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });
            const { latitude, longitude } = position.coords;
            setCoordinates({ latitude, longitude });
        } catch (error) {
            console.error("Error getting geolocation:", error);
        }
    };

    useEffect(() => {
        if ("geolocation" in navigator) {
            fetchGeolocation();
        }
    }, []);

    return (
        <GeolocationContext.Provider value={coordinates}>
            {children}
        </GeolocationContext.Provider>
    );
};

export const useGeolocation = () => {
    const coordinates = useContext(GeolocationContext);
    return coordinates;
};
