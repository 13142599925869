/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {ILayout, useLayout} from '../../core'
import {FooterStyles} from './FooterStyles'
import {useNavigate} from 'react-router-dom'

const Footer = () => {
  const {config} = useLayout()
  const navigate = useNavigate()

  const navigateToAboutUs = () => {
    navigate('/about-us')
  }
  const navigateToFAQs = () => {
    navigate('/faq')
  }

  const navigateToContactUs = () => {
    navigate('/contact-us')
  }

  const navigateToTermsAndConditions = () => {
    navigate('/terms-and-conditions')
  }

  const navigateToPrivacyAndPolicy = () => {
    navigate('/privacy-and-policy')
  }

  
  
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      <FooterStyles>
        <footer>
          <div className=''>
            <section className='justify-content-between ft-main my-6'>
              <div className='ft-main-item'>
                <h2 className='ft-title'>Get In Touch</h2>
                <ul className='mainDiscUL ps-0'>
                  <li>
                    <span>info@coolbro.com</span>
                  </li>
                  <li>
                    <span>0261 253 2253</span>
                  </li>
                  <li>
                    <a className='d-flex justify-content-around align-items-center my-5' href='#'>
                      <i className='fab fa-facebook fs-1 text-white' />
                      <i className='fab fa-instagram fs-1 text-white' />
                      <i className='fab fa-twitter fs-1 text-white' />
                    </a>
                  </li>
                </ul>
              </div>
              <div className='ft-main-item'>
                <h2 className='ft-title'>Useful Links</h2>
                <ul className='mainDiscUL ps-0'>
                  <li className='cursor-pointer text-white' onClick={navigateToAboutUs}>
                    <span>About Us</span>
                  </li>
                  <li className='cursor-pointer text-white' onClick={navigateToContactUs}>
                    <span>Contact Us</span>
                  </li>
                  <li className='cursor-pointer text-white' onClick={navigateToPrivacyAndPolicy}>
                    <span>Privacy & Policy</span>
                  </li>
                  <li className='cursor-pointer text-white' onClick={navigateToFAQs}>
                    <span>FAQs</span>
                  </li>
                  <li className='cursor-pointer text-white' onClick={navigateToTermsAndConditions}>
                    <span>Terms & Condition</span>
                  </li>
                </ul>
              </div>
              <div className='ft-main-item'>
                <h2 className='ft-title'>Address</h2>
                <ul className='mainDiscUL ps-0'>
                  <li>
                    <span>1st floor, Nilkanth Chambers, near Sai Baba Temple,</span>
                    <span>Katargam Darwaja, Surat, Gujarat 395004.</span>
                  </li>
                </ul>
              </div>
            </section>
            <section className='ft-social'>
              <ul className='mainDiscUL ft-social-list'>
                <li>
                  <a href='/'>© Copyright 2023 CoolBro. All rights reserved</a>
                </li>
              </ul>
            </section>
          </div>
        </footer>
      </FooterStyles>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
