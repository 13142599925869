import styled from 'styled-components';

export const FooterStyles = styled.div`

footer {
  background-color: #262262;
  color: #bbb;
  line-height: 1.5;
}
footer a {
  text-decoration: none;
  color: #eee;
  font-size: 15px;
  font-weight: 500;
  margin-top: 7px;
  margin-bottom: 7px;
  display: block;
}
footer span {
  color: #eee;
  font-size: 15px;
  font-weight: 500;
  margin-top: 7px;
  margin-bottom: 7px;
  display: block;
}

.mainDiscUL{
    list-style-type: none;
}

.ft-title {
  color: #fff;
  font-size: 24px;
  padding-bottom: 0.625rem;
}
/* Sticks footer to bottom */


/* Footer main */
.ft-main {
  padding: 1.25rem 1.875rem;
  display: flex;
  
  flex-wrap: wrap;
}
@media only screen and (min-width: 29.8125rem /* 477px */) {
  .ft-main {
    justify-content: space-evenly;
  }
}
@media only screen and (min-width: 77.5rem /* 1240px */) {
  .ft-main {
    justify-content: space-evenly;
  }
}
.ft-main-item {
  padding: 1.25rem;
  min-width: 12.5rem;
}

/* Footer main | Newsletter form */
form {
  display: flex;
  flex-wrap: wrap;
}

/* Footer social */

.ft-social-list {
  display: flex;
  justify-content: center;
  border-top: 1px #777 solid;
  padding-top: 1.25rem;
}
.ft-social-list li {
  margin: 0.5rem;
  font-size: 1.25rem;
}
/* Footer legal */
.ft-legal {
  padding: 0.9375rem 1.875rem;
  background-color: #333;
}
.ft-legal-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.ft-legal-list li {
  margin: 0.125rem 0.625rem;
  white-space: nowrap;
}
/* one before the last child */
.ft-legal-list li:nth-last-child(2) {
    flex: 1;
}`