/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
// import {Link} from 'react-router-dom'
import { useFormik } from 'formik'
import { login } from '../core/_requests'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
// import axios from 'axios'

const loginSchema = Yup.object().shape({
  emailOrPhone: Yup.string()
    .test('is-email-or-phone', 'Invalid email or phone format', function (value: string | undefined) {

      if (!value) { return false; }

      // Custom validation logic to check if it's a valid email or phone
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phoneRegex = /^\d{10}$/;

      return emailRegex.test(value) || phoneRegex.test(value);
    })
    .required('Email or phone is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(20, 'Password must not exceed 20 characters'),
})

const initialValues = {
  emailOrPhone: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [showPassword, setShowPassword] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.emailOrPhone, values.password)
        saveAuth(auth)
        // const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(auth.User)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Please enter valid credentials')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      <form className='form' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
        <div className='marginPageMYMobile mobileDisplayNoneDesktop'>
          <a href='/'>
            <img src={toAbsoluteUrl('/media/logos/logo.png')} className='logoResMobile img-fluid' />
          </a>
        </div>
        <div className='headerLoginText text-center mb-12'>
          <h2 className='primaryTextBold fs-2tx mb-6'>Login</h2>
          <span className='welcomeText fs-xl-3 '>
            Just fill up details below and you are good to go !
          </span>
        </div>

        <div className='mb-8 '>
          <div className='form-group'>
            <input
              id='Email'
              placeholder=''
              {...formik.getFieldProps('emailOrPhone')}
              className={clsx(
                'form-control bg-transparent shadow-none  customInput',
                { 'is-invalid': formik.touched.emailOrPhone && formik.errors.emailOrPhone },
                {
                  'is-valid': formik.touched.emailOrPhone && !formik.errors.emailOrPhone,
                }
              )}
              type='emailOrPhone'
              name='emailOrPhone'
              autoComplete='off'
            />
            <label className='form-label fs-6 fw-bolder ' htmlFor='Email'>
              Phone/Email
            </label>
            {formik.touched.emailOrPhone && formik.errors.emailOrPhone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.emailOrPhone}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='fv-row d-block input-group mb-3'>
          <div className='form-group'>
            <div style={{ position: 'relative' }}>
              <input
                id='Password'
                type={showPassword ? 'text' : 'password'}
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent shadow-none customInput',
                  {
                    'is-invalid1': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid1': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <label className='form-label fw-bolder fs-6 mb-0' htmlFor='Password'>
                Password
              </label>
              <div
                style={{
                  position: 'absolute',
                  right: '10px', // Adjust the position as needed
                  top: '50%', // Adjust the position as needed
                  transform: 'translateY(-50%)', // Center vertically
                  cursor: 'pointer',
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <i className='fas fa-eye'></i> // You can replace this with your own eye icon
                ) : (
                  <i className='fas fa-eye-slash'></i> // You can replace this with your own eye-slash icon
                )}
              </div>
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='d-grid mt-8'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-default primaryBtn'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Login</span>}
            {loading && (
              <span className='indicator-progress d-block'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <div className='d-flex text-center justify-content-center my-4'>
            <span className='fs-5 dontText'>Don't have an account?</span>
            <span className='fs-5 primaryTextBold ms-1 cursor-pointer'>
              <Link to='/auth/registration' className='primaryTextBold'>
                Sign Up
              </Link>
            </span>
          </div>
        </div>
        {formik.status && <div className='text-danger text-center'>{formik.status}</div>}
      </form>
    </>
  )
}
