import {useState} from 'react'
import {Link, useNavigate, useLocation} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'
import {HeaderWrapperStyles} from './HeaderWrapperStyles'
import ResponsiveNavbar from './ResponsiveNavbar/ResponsiveNavbar'
import {useSelector} from 'react-redux'
import {selectCartItems} from '../../../../redux/Slices/cartSlice'

const userAvatarClass = 'symbol-35px symbol-md-40px'

const stylesTransTxt = {
  background: '#F9F9F9',
  borderBottomRightRadius: '8px', // Use camelCased property name
  borderTopRightRadius: '8px', // Use camelCased property name
  borderBottomLeftRadius: '0px', // Use camelCased property name
  borderTopLeftRadius: '0px', // Use camelCased property name
  outline: 'none', // Remove the outline when in focus
}

const stylesTransBtn = {
  background: '#F9F9F9',
  borderBottomLeftRadius: '8px', // Use camelCased property name
  borderTopLeftRadius: '8px', // Use camelCased property name
}

const dropdownButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between', // To align content within the button
  ...stylesTransBtn, // Include your existing button styles
}

interface MenuItemProps {
  icon: string
  text: string
}

export function HeaderWrapper() {
  const location = useLocation()
  const navigate = useNavigate()
  const {currentUser, logout} = useAuth()
  const totalItems = useSelector(selectCartItems)

  const [searchQuery, setSearchQuery] = useState('')

  const handleSearchSubmit = () => {
    if (searchQuery.trim() !== '') {
      // Redirect to the SearchResults page with the search query as a URL parameter
      navigate(`/product-list/${encodeURIComponent(searchQuery)}`)
    }
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      handleSearchSubmit()
    }
  }

  const onClickLogout = () => {
    navigate('/auth')
  }

  const navigateToMyProfile = () => {
    navigate('/user/my-profile')
  }

  const onClickMyCart = () => {
    navigate('/user/cart')
  }

  const onClickDownloadApp = () => {
    navigate('/download-app')
  }

  const {config} = useLayout()
  if (!config.app?.header?.display) {
    return null
  }

  const menuItems = [
    {path: '/product-list', label: 'Product'},
    {path: '/dealer-list', label: 'Dealers'},
  ]

  const iconUrl = searchQuery
    ? 'url("https://example.com/clear-icon.png")'
    : 'url("https://cdn.icon-icons.com/icons2/2551/PNG/512/search_icon_152764.png")'

  // Find the active item based on the current location.
  const activeItem = menuItems.find((item) => item.path === location.pathname)

  function MenuItem({icon, text}: MenuItemProps) {
    return (
      <span className='dropdown-item'>
        <div className='d-flex align-items-center mt-2'>
          <i className={`fs-5 ${icon}`}></i>
          <strong className='fs-6 ps-2 fw-normal moreTextDrp'>{text}</strong>
        </div>
      </span>
    )
  }

  return (
    <>
      <HeaderWrapperStyles>
        <div
          id='kt_app_header'
          className='primaryBG  py-3 commonMobilePX border-bottom-0'
          // data-kt-sticky='true'
          // data-kt-sticky-width="{lg: '100%', xl: '100%',md:'100%'}"
          // data-kt-sticky-zindex='95'
        >
          <div className='row ipadDisplayNone'>
            <nav className='navbar navbar-expand-lg navbar-light bg-transparent'>
              <div className='container-fluid px-0'>
                <span className='navbar-brand me-10'>
                  <Link to='/' className=''>
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/logos/logo.png')}
                      className='h-30px'
                    />
                  </Link>
                </span>
                <div className='collapse navbar-collapse' id='navbarTogglerDemo02'>
                  {/* <div className='row justify-content-lg-around'> */}
                  <div className='col-lg-6 col-md-6 col-xxl-8 col-xl-7 text-white'>
                    <div className='input-group '>
                      <button
                        className='btn btn-outline-secondary dropdown-toggle bg-white me-1'
                        type='button'
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                      >
                        {activeItem ? activeItem.label : 'Products'}
                      </button>
                      <ul className='dropdown-menu'>
                        {menuItems.map((menuItem) => (
                          <li key={menuItem.path}>
                            <Link
                              to={menuItem.path}
                              className={`dropdown-item ${
                                activeItem && activeItem.path === menuItem.path ? 'active' : ''
                              }`}
                            >
                              {menuItem.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                      <input
                        type='search'
                        placeholder='Search'
                        className='searchInputBox bg-white form-control border-0 ms-1'
                        aria-label='Text input with dropdown button'
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyPress={handleKeyPress}
                        style={{
                          backgroundImage: iconUrl,
                          backgroundPosition: 'right 2% center',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: '3%',
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-6 col-xl-5 col-xxl-4 px-0 text-white'>
                    <div className='d-flex ms-10 align-items-center justify-content-around'>
                      <div className=''>
                        {currentUser ? (
                          currentUser && (
                            <div className='dropdown'>
                              <button
                                style={dropdownButtonStyle}
                                className='py-1 w-175px  d-flex justify-content-evenly align-items-center btn btn-secondary dropdown-toggle'
                                type='button'
                                id='dropdownMenuButton1'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                              >
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex flex-column justify-content-center align-items-end pe-2'>
                                    <small className='text-black '>Welcome</small>
                                    <small className='text-black '>{currentUser.Name}</small>
                                  </div>
                                  <div
                                    className={`rounded-circle cursor-pointer  ${userAvatarClass}`}
                                  >
                                    <img
                                      height={30}
                                      width={30}
                                      className='rounded-circle'
                                      src={toAbsoluteUrl('/media/avatars/300-1.jpeg')}
                                      alt=''
                                    />
                                  </div>
                                </div>
                              </button>
                              <ul
                                className='dropdown-menu w-100'
                                aria-labelledby='dropdownMenuButton1'
                              >
                                <li>
                                  <button
                                    onClick={navigateToMyProfile}
                                    className='dropdown-item cursor-pointer'
                                  >
                                    My Profile
                                  </button>
                                </li>
                                <li>
                                  <button onClick={logout} className='dropdown-item cursor-pointer'>
                                    Logout
                                  </button>
                                </li>
                              </ul>
                            </div>
                          )
                        ) : (
                          <button
                            style={stylesTransBtn}
                            type='button'
                            onClick={onClickLogout}
                            className='py-3 d-flex align-items-center  btn btn-default'
                          >
                            <i className='text-black fa fa-sign-in pe-2' aria-hidden='true'></i>
                            <h2 className='mb-0 fs-lg-4 fs-md-3'>Login</h2>
                          </button>
                        )}
                      </div>
                      <div className=''>
                        <button
                          style={stylesTransBtn}
                          type='button'
                          className='d-flex align-items-center cartImgMain px-0 bg-transparent  btn btn-default'
                          onClick={onClickMyCart}
                        >
                          <div className='position-relative'>
                            <img
                              alt='Cart'
                              src={toAbsoluteUrl('/media/vectors/Cart.png')}
                              className='img-fluid'
                            />
                            <div className='cartCounterMain position-absolute end-0 top-0 bg-danger text-white rounded-circle'>
                              {totalItems}
                            </div>
                          </div>
                          <small className='text-white ps-3 fw-bold mt-1 fs-6'>Cart</small>
                        </button>
                      </div>
                      <div className='mt-1'>
                        <li className='fs-6 fw-bold nav-item dropdown list-style-none cursor-pointer'>
                          <span
                            className='fs-6 fw-bold nav-link dropdown-toggle pe-0'
                            id='navbarDropdownMenuLink'
                            data-bs-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                          >
                            More
                          </span>
                          <div className='dropdown-menu' aria-labelledby='navbarDropdownMenuLink'>
                            <MenuItem icon='fa fa-bell' text='Notifications' />
                            <div className='cursor-pointer' onClick={onClickDownloadApp}>
                              <MenuItem icon='fa fa-download' text='Download App' />
                            </div>
                          </div>
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <div className='mobileDisplayNoneDesktop'>
            <ResponsiveNavbar />
          </div>
        </div>
      </HeaderWrapperStyles>
    </>
  )
}
