/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { getUserByToken, register } from '../core/_requests'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  confirmPassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  phoneNumber: Yup.string()
    .matches(/^\d{10}$/, 'Invalid phone number format')
    .required('Phone number is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(20, 'Password must not exceed 20 characters')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    ),
  confirmPassword: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(20, 'Maximum 20 characters')
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
  changepassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPassword1, setShowPassword1] = useState(false)

  const { saveAuth, setCurrentUser } = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await register(
          values.email,
          values.firstname,
          values.lastname,
          values.password,
          values.changepassword
        )
        saveAuth(auth)
        const { data: user } = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form className='form' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
      <div className='marginPageMYMobile mobileDisplayNoneDesktop'>
        <a href='/'>
          <img
            src={toAbsoluteUrl('/media/logos/logo.png')}
            className='logoResMobile img-fluid'
          />
        </a>
      </div>
      <div className='headerLoginText text-center mb-12'>
        <h2 className='primaryTextBold fs-2tx mb-6'>Register</h2>
        <span className='welcomeText fs-xl-3 '>
          Just fill up details below and you are good to go !
        </span>
      </div>
      <div className='fv-row mb-8  d-block input-group  form-group'>
        <div className='form-group'>

          <input
            id="Name"
            placeholder=''
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control bg-transparent shadow-none  customInput',
              { 'is-invalid': formik.touched.firstname && formik.errors.firstname },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
            type='name'
            name='firstname'
            autoComplete='off'
          />
          <label className='form-label fs-6 fw-bolder ' htmlFor='Name'>Name</label>
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='fv-row mb-8 d-block input-group form-group'>
        <div className='form-group'>
          <input
            id='Email'
            placeholder=''
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent shadow-none  customInput',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          <label className='form-label fs-6 fw-bolder ' htmlFor='Email'>Phone Number / Email Address</label>
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='fv-row d-block input-group form-group mb-8'>
        <div className='form-group'>
          <div style={{ position: 'relative' }}>
            <input
              id='pass'
              type={showPassword ? 'text' : 'password'}
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent shadow-none  customInput',
                {
                  'is-invalid1': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid1': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <label className='form-label fw-bolder fs-6 mb-0' htmlFor='pass'>Password</label>
            <div
              style={{
                position: 'absolute',
                right: '10px', // Adjust the position as needed
                top: '50%', // Adjust the position as needed
                transform: 'translateY(-50%)', // Center vertically
                cursor: 'pointer',
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <i className='fas fa-eye'></i> // You can replace this with your own eye icon
              ) : (
                <i className='fas fa-eye-slash'></i> // You can replace this with your own eye-slash icon
              )}
            </div>
          </div>

          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='fv-row d-block input-group form-group mb-3'>
        <div className='form-group'>
          <div className='position-relative'>
            <input
              id='cPass'
              type={showPassword1 ? 'text' : 'password'}
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('confirmPassword')}
              className={clsx(
                'form-control bg-transparent shadow-none  customInput',
                {
                  'is-invalid1': formik.touched.confirmPassword && formik.errors.confirmPassword,
                },
                {
                  'is-valid1': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                }
              )}
            />
            <label className='form-label fw-bolder fs-6 mb-0' htmlFor='cPass'>Confirm Password</label>
            <div
              style={{
                position: 'absolute',
                right: '10px', // Adjust the position as needed
                top: '50%', // Adjust the position as needed
                transform: 'translateY(-50%)', // Center vertically
                cursor: 'pointer',
              }}
              onClick={() => setShowPassword1(!showPassword1)}
            >
              {showPassword1 ? (
                <i className='fas fa-eye'></i> // You can replace this with your own eye icon
              ) : (
                <i className='fas fa-eye-slash'></i> // You can replace this with your own eye-slash icon
              )}
            </div>
          </div>
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='d-grid mt-8'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-default primaryBtn'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Sign Up</span>}
          {loading && (
            <span className='indicator-progress d-block'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <div className='d-flex text-center justify-content-center my-4'>
          <span className='fs-5 dontText'>Already have an account? </span>
          <span className='fs-5 primaryTextBold ms-1 cursor-pointer'>
            <Link to='/auth/login' className='primaryTextBold'>
              Login
            </Link>
          </span>
        </div>
      </div>
      {formik.status && <div className='text-danger text-center'>{formik.status}</div>}
    </form>
  )
}
