import React, { useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../helpers'
import './ResponsiveNavbar.css'
import { useAuth } from '../../../../../app/modules/auth'
import { useSelector } from 'react-redux';
import { selectCartItems } from '../../../../../redux/Slices/cartSlice';

const stylesTransTxt = {
  background: '#F9F9F9',
  borderBottomRightRadius: '8px', // Use camelCased property name
  borderTopRightRadius: '8px', // Use camelCased property name
  borderBottomLeftRadius: '0px', // Use camelCased property name
  borderTopLeftRadius: '0px', // Use camelCased property name
  outline: 'none', // Remove the outline when in focus
}
const stylesTransBtn = {
  background: '#F9F9F9',
  borderBottomLeftRadius: '8px', // Use camelCased property name
  borderTopLeftRadius: '8px', // Use camelCased property name
}
export default function ResponsiveNavbar() {
  const [show, setShow] = useState(false)
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate()
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const totalItems = useSelector(selectCartItems);

  const onClickMyCart = () => {
    navigate('/cart')
  }

  const onClickLogout = () => {
    navigate('/auth')
  }

  const navigateToMyProfile = () => {
    navigate('/user/my-profile')
  }
  const onClickDownloadApp = () => {
    navigate('/download-app')
  }
  return (
    <>
      <div>
        <div className='row justify-content-center'>
          <div className='px-0 py-2 d-flex justify-content-between'>
            <div className='d-flex align-items-center'>
              <div className='d-flex align-items-center d-none' onClick={handleShow}>
                <div className='hamburger-container ms-0'>
                  <ul className='hamburger ps-0'>
                    <li className='list-style-none'></li>
                    <li className='list-style-none'></li>
                    <li className='list-style-none'></li>
                  </ul>
                </div>
              </div>
              <Link to='/dashboard' className=''>
                <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-30px' />
              </Link>
            </div>
            <div className='d-flex justify-content-end align-items-center'>
              <div className=''>
                <button
                  style={stylesTransBtn}
                  type='button'
                  className='bg-transparent respoBtnBright cartImgMain px-0  btn btn-default'
                  onClick={onClickMyCart}
                >
                  <div className='position-relative'>
                    <img
                      alt='Cart'
                      src={toAbsoluteUrl('/media/vectors/Cart.png')}
                      className='img-fluid'
                    />
                    <div className='cartCounterMain position-absolute end-0 top-0 bg-danger text-white rounded-circle'>
                      {totalItems}
                    </div>
                  </div>
                </button>
              </div>
              <div className='ms-6'>
                <div className='btn-group'>
                  {currentUser ? (
                    currentUser && (
                      <>
                        <button
                          style={stylesTransBtn}
                          type='button'
                          className='px-0 bg-transparent   btn btn-default'
                          data-bs-toggle='dropdown'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                        >
                          <img
                            alt='Cart'
                            src={toAbsoluteUrl('/media/avatars/300-1.jpeg')}
                            className='w-30px h-30px rounded-circle'
                          />
                        </button>
                        <div className='dropdown-menu menu menu-sub w-120px menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'>
                          <div
                            className='fs-6 py-2 dropdown-item menu-item  px-3 text-end'
                            onClick={navigateToMyProfile}
                          >
                            My Profile
                          </div>
                          <div className='fs-6 py-2 dropdown-item menu-item  px-3 text-end'>
                            Notifications
                          </div>
                          <div
                            className='fs-6 py-2 dropdown-item menu-item px-3 text-end'
                            data-bs-toggle='dropdown'
                            data-kt-menu-trigger='click'
                            onClick={logout}
                          >
                            Logout
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    <>
                      <button
                        style={stylesTransBtn}
                        type='button'
                        className='respoBtnBright px-0 bg-transparent   btn btn-default'
                        data-bs-toggle='dropdown'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                      >
                        <img
                          alt='Cart'
                          src={toAbsoluteUrl('/media/vectors/mobileAvatarDefault.png')}
                          className='avatarImg'
                        />
                      </button>
                      <div className='dropdown-menu menu menu-sub w-120px menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'>
                        <div className='fs-6 py-2 dropdown-item menu-item  px-3 text-end' onClick={onClickLogout}>
                          Login
                        </div>
                        <div className='fs-6 py-2 dropdown-item menu-item  px-3 text-end'>
                          Notifications
                        </div>
                        <div
                          onClick={onClickDownloadApp}
                          className='fs-6 py-2 dropdown-item menu-item px-3 text-end'
                        >
                          Download App
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='row mobilePaddingNone'>
            <div className='px-0'>
              <div className='input-group'>
                <button
                  className='btn btn-outline-secondary dropdown-toggle bg-white '
                  type='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  Products
                </button>
                <ul className='dropdown-menu'>
                  <li>
                    <Link to='/product-list' className='dropdown-item'>
                      Product
                    </Link>
                  </li>
                  <li>
                    <Link to='/dealer-list' className='dropdown-item'>
                      Dealers
                    </Link>
                  </li>
                </ul>
                <input
                  style={{
                    ...stylesTransTxt,
                  }}
                  type='text'
                  placeholder='Search'
                  className='customSearchInp bg-white form-control border-0 ms-1 py-5'
                  aria-label='Text input with dropdown button'
                />
              </div>
            </div>
          </div>

          {/* <div className='input-group '>
                      <button
                        className='btn btn-outline-secondary dropdown-toggle bg-white me-1'
                        type='button'
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                      >
                        Products
                      </button>
                      <ul className='dropdown-menu'>
                        <li>
                          <Link to='/product-list' className='dropdown-item'>
                            Product
                          </Link>
                        </li>
                        <li>
                          <Link to='/dealer-list' className='dropdown-item'>
                            Dealers
                          </Link>
                        </li>
                      </ul>
                      <input
                        style={{
                          ...stylesTransTxt,
                        }}
                        type='text'
                        placeholder='Search'
                        className='customSearchInp bg-white form-control border-0 ms-1 py-5'
                        aria-label='Text input with dropdown button'
                      />
                    </div> */}
        </div>
      </div>

      <Offcanvas className='offcanvasDemo' show={show} onHide={handleClose} placement='start'>
        <Offcanvas.Header className='headerCanvas' closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you have chosen. Like,
          text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
